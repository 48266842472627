.thankYouMessage {
  margin: 0;
}

.formSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.successMessage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: green;
  margin-bottom: 1rem;
}

.successMessage h4 {
  margin: 0;
  text-align: center;
  width: 100%; /* Ensures the text is centered */
}

.checkmark {
  font-size: 1.5rem;
  color: green;
  position: absolute;
  left: -25px; /* Position checkmark to the left of the text */
}

.formElement {
  margin: 5px !important;
}
.formButton {
  background-color: #333; /* Light grey background */
  border: 1px solid #dadada;
  border-radius: 4px;
  box-shadow: rgba(90, 90, 90, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #dadada; /* Dark grey text color */
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember", sans-serif;
  font-size: 13px;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  min-width: 150px; /* Ensure button width accommodates the text */
}

.formButton:hover {
  background-color: #e6e6e6; /* Lighter grey on hover */
  color: #717171; /* Dark grey text color */
}

.formButton:active {
  border-color: #595959; /* Dark grey border on focus */
  box-shadow: rgba(92, 92, 92, 0.5) 0 2px 5px 0;
  outline: 0;
  transform: scale(0.95);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust the height as needed */
}

.spinner {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #555555; /* Dark grey */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.formElement {
  margin: 0.2rem;
}
.hidden {
  visibility: hidden;
}

input,
textarea {
  background-color: #333;
  color: #fff; /* Text color */
  border: 1px solid #555; /* Default border color */
  padding: 10px; /* Adjust padding as needed */
  border-radius: 4px; /* Optional: Rounded corners */
  outline: #ccc;
}

input.valid,
textarea.valid {
  border-color: #00ff33;
  box-shadow: 0 0 5px rgba(0, 255, 51, 0.5); /* Green border and shadow for valid state */
}

input.invalid,
textarea.invalid {
  border-color: red;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.5); /* Red border and shadow for invalid state */
}

/* Apply hover effect to both input and textarea, ensuring the border color does not change */

input.valid:hover,
textarea.valid:hover {
  border-color: #00ff33;
  background-color: #464545; /* Inherit box shadow */
}

input.invalid:hover,
textarea.invalid:hover {
  border-color: red;
  background-color: #464545; /* Inherit box shadow */
}

/* Style placeholder text for both input and textarea */
input::placeholder,
textarea::placeholder {
  color: #ccc; /* Placeholder text color */
}

label {
  display: block; /* Make labels block-level elements */
  margin-bottom: 5px; /* Add spacing between label and input field */
  color: var(--font-color, white) !important;
  font-weight: 500 !important;
  text-shadow: none !important;
}

.formError {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  color: red;
}

.formError h3,
h4 {
  margin: 0;

  text-align: center;
}

.formError h4 {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.errorIcon {
  font-size: 2rem;
  color: red;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardForm {
  display: flex;
  /* justify-content: center;
  align-items: center;  */
  width: 100%;
  padding: 2rem;
}

.formBody {
  display: flex;
  width: 100%;
  /* align-items: flex-start; */
  flex-direction: column;
  padding: 0;
}

.formBody > * {
  margin: 10px;
}

.formElement {
  text-align: center;
}

.formComment {
  min-width: 60%;
  min-height: 100px;
}

.feedback {
  color: rgb(255, 0, 0);
}

.text-sm {
  font-size: 0.7rem;
}

.placeholderForm {
  display: block;
  box-sizing: content-box;
  height: 0.8rem;
}

.formButton {
  background-color: #d9d9d923 !important; /* Light grey background */
  border: 1px solid #dadada;
  border-radius: 8px;
  box-shadow: rgba(90, 90, 90, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #ffffff !important; /* Dark grey text color */
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember", sans-serif;
  font-size: 13px;
  font-weight: 800;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: max-content;
  align-self: center;
}

.formButton:hover {
  background-color: #ffffff5f !important; /* Lighter grey on hover */
  color: #4b4949cb !important; /* Dark grey text color */
}

.formButton:active {
  border-color: #59595939; /* Dark grey border on focus */
  box-shadow: rgba(92, 92, 92, 0.5) 0 2px 5px 0;
  outline: 0;
  transform: scale(0.95);
}

input,
textarea {
  background-color: #33333327 !important;
  color: #fff; /* Text color */
  border: 1px solid #555; /* Border color */
  padding: 10px; /* Adjust padding as needed */
  border-radius: 4px; /* Optional: Rounded corners */
  outline: #ccc;
}

input:focus,
textarea:focus {
  border-color: #00ff33;
  box-shadow: 0 0 5px rgba(204, 204, 204, 0.5); /* Optional: Add a subtle box shadow */
}

/* Style placeholder text for both input and textarea */
input::placeholder,
textarea::placeholder {
  color: #ccc; /* Placeholder text color */
}

/* Apply hover effect to both input and textarea */
input:hover,
textarea:hover {
  border-color: #7777775d; /* Adjust hover border color as needed */
}

label {
  display: block; /* Make labels block-level elements */
  margin-bottom: 5px; /* Add spacing between label and input field */
}
label {
  font-weight: 800;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3),
    1px 1px 1px rgba(0, 0, 0, 0.3);
}

@media (prefers-color-scheme: dark) {
  /* Dark theme overrides for the contact form section */

  /* Adjust form button styles */
  .formButton {
    background-color: rgba(0, 0, 0, 0.5) !important;
    /* border: 1px solid #444; */
    box-shadow: rgba(0, 0, 0, 0.7) 0 2px 5px 0;
    color: #ffffff !important;
  }
  .formButton:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
    color: #ffffff !important;
  }

  /* Adjust input and textarea styles */
  input,
  textarea {
    background-color: #222 !important;
    /* border: 1px solid #444 !important; */
    color: #ffffff;
  }

  /* Ensure labels use the correct font color */
  label {
    color: var(--font-color, white) !important;
    text-shadow: none !important;
  }

  /* Adjust spinner for dark background */
  .spinner {
    border: 4px solid #444;
    border-top: 4px solid #ffffff;
  }
}

@media (prefers-color-scheme: light) {
  /* Light theme overrides for the contact form section */

  /* Adjust form button styles */
  .formButton {
    background-color: #f0f0f0 !important; /* Light background */
    border: 1px solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 5px 0;
    color: #333 !important;
  }
  .formButton:hover {
    background-color: #e0e0e0 !important;
    color: #222 !important;
  }
  .formButton:active {
    border-color: #999;
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 5px 0;
    transform: scale(0.95);
  }

  /* Adjust input and textarea styles */
  input,
  textarea {
    background-color: #fff !important;
    color: #000;
  }

  input:focus,
  textarea:focus {
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  /* Ensure labels use a dark font color for better contrast */
  label {
    color: #000 !important;
    text-shadow: none !important;
  }

  /* Adjust spinner for light background */
  .spinner {
    border: 4px solid #ddd;
    border-top: 4px solid #555;
  }
}
