@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&family=Parisienne&family=Pacifico&family=Satisfy&family=Alex+Brush&family=Allura&family=Yellowtail&family=Rouge+Script&family=Amatic+SC&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.intro {
  display: block;
  /* justify-content: center; */
  /* padding: 0 !important; */

  color: rgb(255, 255, 255);
  /* animation: flash-txt 8.5s linear 0s, flash-txt 8.5s linear 11s infinite; Run immediately and then after a delay */
  /* text-shadow: 2px 2px 18px rgb(4, 107, 252); */
  text-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.5);
}

.innerIntro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* max-width: min-content; */
  /* flex-wrap: wrap-reverse; */
  /* max-width: 60%; */
  /* padding-block: 8vh; */
  z-index: 2;

  /* justify-content: flex-start; */
}

.intro-text {
  font-size: clamp(2.9rem, 5vw, 3.5rem) !important;
  font-family: "Inter", sans-serif;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2),
    /* subtle inner shadow */ 2px 2px 0px rgba(0, 0, 0, 0.2),
    3px 3px 0px rgba(0, 0, 0, 0.2), 4px 4px 0px rgba(0, 0, 0, 0.2);
  margin-block: 1rem;
}

/* Main container styles */
.jumbotron {
  position: relative; /* So that absolutely positioned children are relative to this */
  overflow-y: visible;
  justify-content: center !important;
  background: transparent; /* Transparent background so the frothy layer shows */
}
.outerBox {
  position: relative;
  display: flex;
  padding-top: 20px;
  overflow-y: hidden;
  overflow-x: hidden;
  /* justify-items: center;
  align-items: center;
  justify-content: center; */
  /* gap: 0; */
  width: 100%;
  height: 100%;
  background: transparent;
}

/* These two elements will expand to fill the remaining space on the left and right */
.outerBoxLeftBlur,
.outerBoxRightBlur {
  flex-grow: 1;
  height: 400px;
  pointer-events: none;
  z-index: 2;
  min-width: 24px;
}

/* Left blur: gradient from opaque at the edge to transparent inward */
.outerBoxLeftBlur {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), transparent);
  backdrop-filter: blur(2px);
}

/* Right blur: gradient from opaque at the edge to transparent inward */
.outerBoxRightBlur {
  background: linear-gradient(to left, rgba(255, 255, 255, 0), transparent);
  backdrop-filter: blur(2px);
}

/* Bubble animations remain unchanged */

.bubbles {
  position: absolute;
  width: 100%;
  height: 95%;
  pointer-events: none; /* Prevent the bubbles from interfering with interactions */
  z-index: 1;
}

.bubble {
  position: absolute;
  border-radius: 50%;
  left: 45%;
  padding: 20px 30px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  white-space: nowrap;
  /* background: radial-gradient(
    ellipse at center,
    rgba(0, 42, 255, 0.09) 0%,
    rgba(191, 0, 255, 0) 60%
  ); */
  /* backdrop-filter: blur(5px); */
  animation: float-right 5s infinite linear;
  opacity: 0;
  font-weight: 800;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1), 2px 2px 1px rgba(0, 0, 0, 0.05);
}
@media (prefers-color-scheme: light) {
  .bubble {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}
/* .bubble-bg {
  position: absolute;
  border-radius: 50%;
  left: -30px;
  top: -20px;
  padding: 20px 30px;
  color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  white-space: nowrap;
  background: radial-gradient(
    ellipse at center,
    rgba(138, 36, 255, 0.156) 0%,
    rgba(228, 146, 255, 0) 80%
  );
  filter: blur(15px);
} */

.bubble:nth-child(odd) {
  animation: float-left 5s infinite linear;
}

@keyframes float-right {
  0% {
    transform: translateX(-70vw);
    /* opacity: 0; */
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateX(55vw);
    /* opacity: 0; */
  }
}

@keyframes float-left {
  0% {
    transform: translateX(55vw);
    /* opacity: 0; */
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateX(-70vw);
    /* opacity: 0; */
  }
}
